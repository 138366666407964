import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Subscription', path: PATH_DASHBOARD.dorm.dorm, icon: <Icon icon="material-symbols-light:subscriptions-rounded" /> },
      // { title: 'Video', path: PATH_DASHBOARD.element.element, icon: <Icon icon="octicon:organization-16" /> },
      { title: 'Pets', path: PATH_DASHBOARD.spell.spell, icon: <Icon icon="ri:dashboard-line" /> },
      // { title: 'Donation',  path: '/dashboard/rarity' , icon:<Icon icon="ri:dashboard-line" /> },
    ],
  },
];

export default navConfig;
  